import React from 'react';
import { cn } from '../lib/utils/cn';

export function OpenLogoPatternSvg({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('text-primary-foreground', className)}
      style={style}
    >
      <title>Open Logo Pattern</title>
      <path
        d="M5.18154 12.7522L8.73348 14.8029C8.98814 14.961 9.17302 15.2102 9.25054 15.4998C9.32806 15.7894 9.29241 16.0977 9.15083 16.3621L7.09906 19.9159C6.95748 20.1803 6.92183 20.4886 6.99935 20.7782C7.07688 21.0678 7.26175 21.317 7.51641 21.4751L16.6659 26.7575C16.9301 26.899 17.2384 26.9345 17.5279 26.8569C17.8175 26.7792 18.0667 26.5942 18.2249 26.3394L20.2766 22.7856C20.4351 22.5303 20.685 22.3451 20.9752 22.2677C21.2655 22.1903 21.5743 22.2266 21.8386 22.3692L25.3755 24.4112C25.6397 24.5527 25.9479 24.5882 26.2375 24.5105C26.5271 24.4329 26.7763 24.2478 26.9344 23.993L35.4061 9.31978C35.5476 9.05541 35.5833 8.74709 35.5058 8.45747C35.4282 8.16786 35.2434 7.91868 34.9887 7.7606L31.4398 5.71162C31.1837 5.55365 30.9977 5.30371 30.9199 5.01297C30.842 4.72223 30.8783 4.41268 31.0212 4.14769L33.0712 0.596929C33.2118 0.33467 33.2482 0.0289995 33.173 -0.258845C33.0978 -0.54669 32.9167 -0.795451 32.6659 -0.955292L23.5164 -6.23775C23.2522 -6.37925 22.9439 -6.41477 22.6544 -6.3371C22.3648 -6.25942 22.1156 -6.07439 21.9574 -5.8196L19.9074 -2.26883C19.7497 -2.01308 19.5004 -1.8271 19.2104 -1.74884C18.9204 -1.67057 18.6115 -1.70592 18.3467 -1.84767L14.7948 -3.89838C14.5305 -4.03988 14.2223 -4.0754 13.9327 -3.99773C13.6432 -3.92005 13.394 -3.73502 13.2358 -3.48023L4.76419 11.193C4.62262 11.4574 4.58697 11.7657 4.66449 12.0553C4.74201 12.345 4.92689 12.5941 5.18154 12.7522ZM10.2924 14.3848L18.7641 -0.288487C18.9222 -0.543279 19.1714 -0.728316 19.461 -0.805987C19.7506 -0.883659 20.0588 -0.848137 20.323 -0.706641L29.4725 4.57582C29.7272 4.7339 29.912 4.98307 29.9896 5.27269C30.0671 5.56231 30.0314 5.87063 29.8899 6.13499L21.4182 20.8083C21.2601 21.063 21.0109 21.2481 20.7213 21.3258C20.4317 21.4034 20.1235 21.3679 19.8593 21.2264L10.7098 15.944C10.4551 15.7859 10.2703 15.5367 10.1927 15.2471C10.1152 14.9575 10.1509 14.6491 10.2924 14.3848Z"
        fill="currentColor"
      />
      <path
        d="M31.1815 27.7522L34.7335 29.8029C34.9881 29.961 35.173 30.2102 35.2505 30.4998C35.3281 30.7894 35.2924 31.0977 35.1508 31.3621L33.0991 34.9159C32.9575 35.1803 32.9218 35.4886 32.9994 35.7782C33.0769 36.0678 33.2618 36.317 33.5164 36.4751L42.6659 41.7575C42.9301 41.899 43.2384 41.9345 43.5279 41.8569C43.8175 41.7792 44.0667 41.5942 44.2249 41.3394L46.2766 37.7856C46.4351 37.5303 46.685 37.3451 46.9752 37.2677C47.2655 37.1903 47.5743 37.2266 47.8386 37.3692L51.3755 39.4112C51.6397 39.5527 51.9479 39.5882 52.2375 39.5105C52.5271 39.4329 52.7763 39.2478 52.9344 38.993L61.4061 24.3198C61.5476 24.0554 61.5833 23.7471 61.5058 23.4575C61.4282 23.1679 61.2434 22.9187 60.9887 22.7606L57.4398 20.7116C57.1837 20.5537 56.9977 20.3037 56.9199 20.013C56.842 19.7222 56.8783 19.4127 57.0212 19.1477L59.0712 15.5969C59.2118 15.3347 59.2482 15.029 59.173 14.7412C59.0978 14.4533 58.9167 14.2045 58.6659 14.0447L49.5164 8.76225C49.2522 8.62075 48.9439 8.58523 48.6544 8.6629C48.3648 8.74058 48.1156 8.92561 47.9574 9.1804L45.9074 12.7312C45.7497 12.9869 45.5004 13.1729 45.2104 13.2512C44.9204 13.3294 44.6115 13.2941 44.3467 13.1523L40.7948 11.1016C40.5305 10.9601 40.2223 10.9246 39.9327 11.0023C39.6432 11.0799 39.394 11.265 39.2358 11.5198L30.7642 26.193C30.6226 26.4574 30.587 26.7657 30.6645 27.0553C30.742 27.345 30.9269 27.5941 31.1815 27.7522ZM36.2924 29.3848L44.7641 14.7115C44.9222 14.4567 45.1714 14.2717 45.461 14.194C45.7506 14.1163 46.0588 14.1519 46.323 14.2934L55.4725 19.5758C55.7272 19.7339 55.912 19.9831 55.9896 20.2727C56.0671 20.5623 56.0314 20.8706 55.8899 21.135L47.4182 35.8083C47.2601 36.063 47.0109 36.2481 46.7213 36.3258C46.4317 36.4034 46.1235 36.3679 45.8593 36.2264L36.7098 30.944C36.4551 30.7859 36.2703 30.5367 36.1927 30.2471C36.1152 29.9575 36.1509 29.6491 36.2924 29.3848Z"
        fill="currentColor"
      />
      <path
        d="M4.18154 44.7522L7.73348 46.8029C7.98814 46.961 8.17302 47.2102 8.25054 47.4998C8.32806 47.7894 8.29241 48.0977 8.15083 48.3621L6.09906 51.9159C5.95748 52.1803 5.92183 52.4886 5.99935 52.7782C6.07688 53.0678 6.26175 53.317 6.51641 53.4751L15.6659 58.7575C15.9301 58.899 16.2384 58.9345 16.5279 58.8569C16.8175 58.7792 17.0667 58.5942 17.2249 58.3394L19.2766 54.7856C19.4351 54.5303 19.685 54.3451 19.9752 54.2677C20.2655 54.1903 20.5743 54.2266 20.8386 54.3692L24.3755 56.4112C24.6397 56.5527 24.9479 56.5882 25.2375 56.5105C25.5271 56.4329 25.7763 56.2478 25.9344 55.993L34.4061 41.3198C34.5476 41.0554 34.5833 40.7471 34.5058 40.4575C34.4282 40.1679 34.2434 39.9187 33.9887 39.7606L30.4398 37.7116C30.1837 37.5537 29.9977 37.3037 29.9199 37.013C29.842 36.7222 29.8783 36.4127 30.0212 36.1477L32.0712 32.5969C32.2118 32.3347 32.2482 32.029 32.173 31.7412C32.0978 31.4533 31.9167 31.2045 31.6659 31.0447L22.5164 25.7623C22.2522 25.6208 21.9439 25.5852 21.6544 25.6629C21.3648 25.7406 21.1156 25.9256 20.9574 26.1804L18.9074 29.7312C18.7497 29.9869 18.5004 30.1729 18.2104 30.2512C17.9204 30.3294 17.6115 30.2941 17.3467 30.1523L13.7948 28.1016C13.5305 27.9601 13.2223 27.9246 12.9327 28.0023C12.6432 28.0799 12.394 28.265 12.2358 28.5198L3.76419 43.193C3.62262 43.4574 3.58697 43.7657 3.66449 44.0553C3.74201 44.345 3.92689 44.5941 4.18154 44.7522ZM9.29245 46.3848L17.7641 31.7115C17.9222 31.4567 18.1714 31.2717 18.461 31.194C18.7506 31.1163 19.0588 31.1519 19.323 31.2934L28.4725 36.5758C28.7272 36.7339 28.912 36.9831 28.9896 37.2727C29.0671 37.5623 29.0314 37.8706 28.8899 38.135L20.4182 52.8083C20.2601 53.063 20.0109 53.2481 19.7213 53.3258C19.4317 53.4034 19.1235 53.3679 18.8593 53.2264L9.7098 47.944C9.45514 47.7859 9.27026 47.5367 9.19274 47.2471C9.11522 46.9575 9.15087 46.6491 9.29245 46.3848Z"
        fill="currentColor"
      />
      <path
        d="M30.1815 59.7522L33.7335 61.8029C33.9881 61.961 34.173 62.2102 34.2505 62.4998C34.3281 62.7894 34.2924 63.0977 34.1508 63.3621L32.0991 66.9159C31.9575 67.1803 31.9218 67.4886 31.9994 67.7782C32.0769 68.0678 32.2618 68.317 32.5164 68.4751L41.6659 73.7575C41.9301 73.899 42.2384 73.9345 42.5279 73.8569C42.8175 73.7792 43.0667 73.5942 43.2249 73.3394L45.2766 69.7856C45.4351 69.5303 45.685 69.3451 45.9752 69.2677C46.2655 69.1903 46.5743 69.2266 46.8386 69.3692L50.3755 71.4112C50.6397 71.5527 50.9479 71.5882 51.2375 71.5105C51.5271 71.4329 51.7763 71.2478 51.9344 70.993L60.4061 56.3198C60.5476 56.0554 60.5833 55.7471 60.5058 55.4575C60.4282 55.1679 60.2434 54.9187 59.9887 54.7606L56.4398 52.7116C56.1837 52.5537 55.9977 52.3037 55.9199 52.013C55.842 51.7222 55.8783 51.4127 56.0212 51.1477L58.0712 47.5969C58.2118 47.3347 58.2482 47.029 58.173 46.7412C58.0978 46.4533 57.9167 46.2045 57.6659 46.0447L48.5164 40.7623C48.2522 40.6208 47.9439 40.5852 47.6544 40.6629C47.3648 40.7406 47.1156 40.9256 46.9574 41.1804L44.9074 44.7312C44.7497 44.9869 44.5004 45.1729 44.2104 45.2512C43.9204 45.3294 43.6115 45.2941 43.3467 45.1523L39.7948 43.1016C39.5305 42.9601 39.2223 42.9246 38.9327 43.0023C38.6432 43.0799 38.394 43.265 38.2358 43.5198L29.7642 58.193C29.6226 58.4574 29.587 58.7657 29.6645 59.0553C29.742 59.345 29.9269 59.5941 30.1815 59.7522ZM35.2924 61.3848L43.7641 46.7115C43.9222 46.4567 44.1714 46.2717 44.461 46.194C44.7506 46.1163 45.0588 46.1519 45.323 46.2934L54.4725 51.5758C54.7272 51.7339 54.912 51.9831 54.9896 52.2727C55.0671 52.5623 55.0314 52.8706 54.8899 53.135L46.4182 67.8083C46.2601 68.063 46.0109 68.2481 45.7213 68.3258C45.4317 68.4034 45.1235 68.3679 44.8593 68.2264L35.7098 62.944C35.4551 62.7859 35.2703 62.5367 35.1927 62.2471C35.1152 61.9575 35.1509 61.6491 35.2924 61.3848Z"
        fill="currentColor"
      />
    </svg>
  );
}
